<template>
    <div class="landing-page-container">
        <GuestHeader ref="home" @move="goTo"></GuestHeader>
        <Home @move="goTo"></Home>
        <AboutUs ref="about"></AboutUs>
        <div class="ads-container">
            <p class="title">{{ $t('ads_desc') }}</p>
            <!-- <p class="desc">Eligible for four (4) takes only and license valid for one year</p> -->
            <!-- <router-link to="/">{{ $t('contact_us') }}</router-link> -->
            <a @click="goTo('contact')">{{ $t('contact_us') }}</a>
        </div>
        <HowItWorks ref="hiw"></HowItWorks>
        <ContactUs ref="contact"></ContactUs>
        <Footer></Footer>
    </div>
</template>

<script>
export default {
	components: { 
        GuestHeader: () => import('@/components/landing-page/GuestHeader'),
        Home: () => import('@/components/landing-page/Home'),
        AboutUs: () => import('@/components/landing-page/AboutUs'),
        HowItWorks: () => import('@/components/landing-page/HowItWorks'),
        ContactUs: () => import('@/components/landing-page/ContactUs'),
        Footer: () => import('@/components/utils/Footer')
    },

    methods: {
        goTo (e) {
            console.log(e)
            var element = this.$refs[e];
            var top = element.$el.offsetTop;

            window.scrollTo({left: 0, top, behavior: 'smooth'});
        },
    },
}
</script>

<style lang="scss" scoped>
.ads-container {
    background-color: #3CB371;
    color: #ffffff;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 20px;

    @include desktop {
        margin-bottom: 100px;
    }

    .title {
        font-family: 'Crimson Pro', sans-serif;
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 20px;
    }

    .desc {
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 40px;
    }

    a {
        color: #ffffff;
        text-decoration: underline;
        font-size: 16px;
    }
}
</style>